var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"histgram_app"},[_c('div',{staticClass:"histogram_box"},[_c('Bar',{attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartData,"chart-id":_vm.chartId,"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.styles,"width":_vm.width,"height":_vm.height}})],1),_c('div',{staticClass:"images_box"},[_vm._l((_vm.ng_list_range),function(item){return _c('div',{key:item.filename,staticClass:"cell"},[_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":item.filename,"width":"50px","height":"50px"}})]),_c('div',{staticClass:"confidence",class:{ 
                    groundtruth_ok : (item.groundtruth ==='OK'),
                    groundtruth_ng : ( item.groundtruth ==='NG' || item.groundtruth === 'foreign_matter' ),
                    dot : (item.groundtruth === 'dot' ),
                    cannot_judge : (item.groundtruth === 'undefined')}},[_vm._v(" "+_vm._s(item.pred.toFixed(3))+" ")])])}),_vm._l((_vm.ok_list_range),function(item){return _c('div',{key:item.filename,staticClass:"cell"},[_c('img',{attrs:{"src":item.filename,"width":"50px"}}),_c('div',{staticClass:"confidence",class:{ 
                    groundtruth_ok : (item.groundtruth ==='OK'),
                    groundtruth_ng : ( item.groundtruth ==='NG' || item.groundtruth === 'foreign_matter' ),
                    dot : (item.groundtruth === 'dot' ),
                    cannot_judge : (item.groundtruth === 'undefined')}},[_vm._v(" "+_vm._s(item.pred.toFixed(3))+" ")])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }