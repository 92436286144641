<template>
  <v-app>
    <v-app-bar
      app
      color="secondary"
      dark
    >
      <div class="d-flex align-center">
        <a href="https://seijikojima.com">
        <v-img
          alt="sabosan logo"
          class="shrink mr-2"
          contain
          src="https://seijikojima.com/cactus.png"
          transition="scale-transition"
          width="40"
        />
        </a>

        <h2>Histogram Application</h2>
      </div>

    </v-app-bar>

    <v-main>
      <Histogram />
    </v-main>
  </v-app>
</template>

<script>
import Histogram from './components/Histogram';

export default {
  name: 'App',

  components: {
    Histogram,
  },

  data: () => ({
    //
  }),
};
</script>
