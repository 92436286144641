<template>
<div class="histgram_app">
  <div class="histogram_box">
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />

    <!-- <v-btn @click="calculate_histogram">button</v-btn> -->

  </div>

  <div class="images_box">

      <div class="cell" v-for="item in ng_list_range " v-bind:key="item.filename">
            <div class="img_box">
              <img v-bind:src="item.filename" width="50px" height="50px">
            </div>
            <div class="confidence" 
                  v-bind:class="{ 
                    groundtruth_ok : (item.groundtruth ==='OK'),
                    groundtruth_ng : ( item.groundtruth ==='NG' || item.groundtruth === 'foreign_matter' ),
                    dot : (item.groundtruth === 'dot' ),
                    cannot_judge : (item.groundtruth === 'undefined')}">
                {{item.pred.toFixed(3)}}
            </div>
      </div>

      <div class="cell" v-for="item in ok_list_range " v-bind:key="item.filename">
            <img v-bind:src="item.filename" width="50px">
            <div class="confidence" 
                  v-bind:class="{ 
                    groundtruth_ok : (item.groundtruth ==='OK'),
                    groundtruth_ng : ( item.groundtruth ==='NG' || item.groundtruth === 'foreign_matter' ),
                    dot : (item.groundtruth === 'dot' ),
                    cannot_judge : (item.groundtruth === 'undefined')}">
                {{item.pred.toFixed(3)}}
            </div>
      </div>

  </div>

</div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },

  mounted() {
    this.calculate_histogram()
  },
  
  methods : {

  calculate_histogram : function () {

    this.items.forEach(item => {
          var i = parseInt( item.pred*10)
          if(item.groundtruth=="OK") this.ok_hist[i]++;
          if (item.groundtruth=="NG") this.ng_hist[i]++;
    })

    this.chartData = {
      labels: [ '0.1', '0.2','0.3','0.4','0.5','0.6','0.7','0.8','0.9','1.0' ],
      datasets: [ { data: this.ok_hist, //[10, 10, 80,5, 3, 0, 0 , 0, 0, 0],
                  backgroundColor : "#4c92c399",
                  label : 'OK',
                  fillAlpha : 0.2 },
                  { data: this.ng_hist, //[10, 2, 5,10, 3, 5,10, 10, 60,100],
                  backgroundColor : "#ff983e99",
                  label : 'NG' }  ]
    }
  },

  get_images_by_click_bar : function(range_prob) {
        this.ok_list_range = []
        this.ng_list_range = []
        
        this.items.forEach (item => {
          if (range_prob < item.pred && item.pred <= range_prob + 0.1) {
            if(item.groundtruth=="OK") this.ok_list_range.push(item)
            else this.ng_list_range.push(item)
          }
        })
        // this.axios.post(endpoint_base + '/get_results_with_probrange', { prob_range : this.prob_range})
        // .then( response => {
        //   response.data.forEach(item => {
        //     if(item.groundtruth=="OK") this.ok_list_range.push(item)
        //     else this.ng_list_range.push(item)
        //   })
        //   console.log(response.data)
        // })
  },

  change_value : function ()  {
    console.log("hoge")
    // datasets: [ { data: [40, 20, 12] } ]
    // this.chartData.datasets = [ { data: } ]
    this.chartData = {
      labels: [ '0.1', '0.2','0.3','0.4','0.5','0.6','0.7','0.8','0.9','1.0' ],
      datasets: [ { data: [10, 10, 80,5, 3, 0, 0 , 0, 0, 0],
                  backgroundColor : "#4c92c399",
                  label : 'OK',
                  fillAlpha : 0.2 },
                  
                  { data: [10, 2, 5,10, 3, 5,10, 10, 60,100],
                  backgroundColor : "#ff983e99",
                  label : 'NG' }  ]
    }
  }

},

  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ok_hist : [...Array(10).fill(0)],
      ng_hist : [...Array(10).fill(0)], // デモのため後で変える
      ok_list_range : [],
      ng_list_range : [],
      items : [
        // ok
        { filename : require("@/assets/img/ok_000.png"), pred : 0.01, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_001.png"), pred : 0.21, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_002.png"), pred : 0.38, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_003.png"), pred : 0.02, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_004.png"), pred : 0.01, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_005.png"), pred : 0.03, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_006.png"), pred : 0.29, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_007.png"), pred : 0.02, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_008.png"), pred : 0.13, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_009.png"), pred : 0.05, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_010.png"), pred : 0.06, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_011.png"), pred : 0.02, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_012.png"), pred : 0.06, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_013.png"), pred : 0.08, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_014.png"), pred : 0.02, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_015.png"), pred : 0.03, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_016.png"), pred : 0.32, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_017.png"), pred : 0.05, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_018.png"), pred : 0.06, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_019.png"), pred : 0.02, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_020.png"), pred : 0.04, groundtruth : "OK"},
        { filename : require("@/assets/img/ok_021.png"), pred : 0.01, groundtruth : "OK"},

        // ng
        { filename : require("@/assets/img/ng_bent_014.png"), pred : 0.74, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_015.png"), pred : 0.72, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_016.png"), pred : 0.92, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_017.png"), pred : 0.84, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_018.png"), pred : 0.88, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_019.png"), pred : 0.78, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_020.png"), pred : 0.77, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_021.png"), pred : 0.89, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_022.png"), pred : 0.88, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_023.png"), pred : 0.79, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_bent_024.png"), pred : 0.89, groundtruth : "NG"},

        { filename : require("@/assets/img/ng_color_014.png"), pred : 0.98, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_color_015.png"), pred : 0.99, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_color_016.png"), pred : 0.97, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_color_017.png"), pred : 0.96, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_color_018.png"), pred : 0.89, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_color_019.png"), pred : 0.89, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_color_020.png"), pred : 0.89, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_color_021.png"), pred : 0.89, groundtruth : "NG"},

        { filename : require("@/assets/img/ng_flip_014.png"), pred : 0.82, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_flip_015.png"), pred : 0.67, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_flip_016.png"), pred : 0.66, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_flip_017.png"), pred : 0.65, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_flip_018.png"), pred : 0.64, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_flip_019.png"), pred : 0.73, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_flip_020.png"), pred : 0.97, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_flip_021.png"), pred : 0.94, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_flip_022.png"), pred : 0.93, groundtruth : "NG"},


        { filename : require("@/assets/img/ng_scratch_014.png"), pred : 0.99, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_scratch_015.png"), pred : 0.99, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_scratch_016.png"), pred : 0.99, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_scratch_017.png"), pred : 0.99, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_scratch_018.png"), pred : 0.99, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_scratch_019.png"), pred : 0.99, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_scratch_020.png"), pred : 0.99, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_scratch_021.png"), pred : 0.99, groundtruth : "NG"},
        { filename : require("@/assets/img/ng_scratch_022.png"), pred : 0.99, groundtruth : "NG"},

      ],

      chartData: {
        labels: [ '0.1', '0.2','0.3','0.4','0.5','0.6','0.7','0.8','0.9','1.0' ],
        datasets: [ { data: this.ok_hist, //[100, 90, 10,5, 3, 0, 0 , 0, 0, 0],
                    backgroundColor : "#4c92c399",
                    label : 'OK',
                    fillAlpha : 0.2 },
                    
                    { data: this.ng_hist,//[10, 2, 5,10, 3, 5,10, 10, 60,100],
                    backgroundColor : "#ff983e99",
                    label : 'NG' }  ]
      },
      chartOptions: {
        responsive: true,
         onClick: (e,el) => {
          //  console.log(e,el);
          //  console.log(el[0].index/10)
           this.get_images_by_click_bar(el[0].index/10)



                // console.log('onClick : label ' + el[0]._model.label.slice(0,3));
                // this.prob_range = 
         },
        scales: {
          x: {
            stacked: true,
          },
      // y: {
      //   stacked: true
      // }
        }
      }
    }
  }
}
</script>

<style scoped>

.histogram_box {
  margin : 30px auto 0 auto;
  padding : 10px;
  width : 60%;
  /* border : solid 3px #111; */
  text-align :center;
  box-shadow: 0 0 8px #333;
  background-color : #fff;
}

.images_box {
  display: flex;
  flex-wrap: wrap;
  margin : 30px auto 0 auto;
  padding : 10px;
  width : 60%;
  /* border : solid 3px #111; */
  text-align :center;
  box-shadow: 0 0 8px #333;
  background-color : #fff;
}




.barchart_title {
  /* margin : 0;
  padding: 0; */
  /* border-bottom : solid 1px#000; */
  color : #333;
  font-size : 20px;
}
.groundtruth_ok {
  background-color : #1e90ff;
}
.groundtruth_ng {
  background-color : #b22222;
}
.dot {
  background-color : #a63683;
}
.cannot_judge {
  background-color : #666666;
}
img {
  margin : 0;
  padding : 0;
  float: left; 
}


</style>